import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import InvoiceService from '../../../services/InvoiceService';
import Invoice from '../../../models/invoice/Invoice';
import { RouterMatch } from '../../../models/RouterMatch';
import { Redirect } from 'react-router';
import { ToastVariant } from '../../../reducers/Snackbar';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import InvoiceEditor from './components/InvoiceEditor';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    invoice?: Invoice;
    existInvoice: boolean;
    isNewInvoice: boolean;
    invoiceType?: 'invoice' | 'receipt';
}

class InvoiceEdit extends Component<Props, State> {
    private invoiceId = '';

    constructor(props: Props) {
        super(props);
        if (props.match.params.id) {
            this.state = {
                isLoading: true,
                existInvoice: true,
                isNewInvoice: false
            };
            this.invoiceId = props.match.params.id;
            this.loadInvoice(this.invoiceId);
        } else {
            this.state = {
                isLoading: false,
                existInvoice: true,
                isNewInvoice: true,
                invoiceType:
                    props.match.params.type === 'receipt'
                        ? 'receipt'
                        : 'invoice'
            };
        }
    }

    loadInvoice = async (invoiceId: string) => {
        try {
            const invoice = await InvoiceService.getInvoice(invoiceId);
            this.setState({ ...this.state, isLoading: false, invoice });
        } catch (err) {
            this.setState(
                { ...this.state, isLoading: false, existInvoice: false },
                () => {
                    this.props.showToast('error', 'Invoice not found');
                }
            );
        }
    };

    render(): ReactElement {
        return (
            <div className='invoice-edit-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            {!this.state.isNewInvoice && (
                                <PageHeader>Edit invoice</PageHeader>
                            )}
                            {this.state.isNewInvoice && (
                                <PageHeader>
                                    Create{' '}
                                    {this.state.invoiceType === 'receipt'
                                        ? 'Receipt Charged'
                                        : 'Invoice'}
                                </PageHeader>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/invoice/list'>
                                Back to Invoice list
                            </BackButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.state.invoice && (
                                <InvoiceEditor
                                    invoice={this.state.invoice}
                                    showToast={this.props.showToast}
                                ></InvoiceEditor>
                            )}
                            {this.state.isNewInvoice && (
                                <InvoiceEditor
                                    showToast={this.props.showToast}
                                    invoiceType={this.state.invoiceType}
                                ></InvoiceEditor>
                            )}
                        </Grid>
                    </Grid>
                </Container>
                {!this.state.existInvoice && !this.state.isNewInvoice && (
                    <div>
                        <Redirect to='/invoice/list' />
                    </div>
                )}
            </div>
        );
    }
}
export default InvoiceEdit;
