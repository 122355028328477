import React, { CSSProperties, useEffect, ReactElement, useState } from 'react';
import { FormControl } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Action as ToastAction } from '../../../../../reducers/Snackbar';
import ReactSelect, {
    ReactSelectOption
} from '../../../../../components/ReactSelect';
import { useDebouncedCallback } from '../../../../../components/useDebouncedCallback';
import { InputActionMeta } from 'react-select/lib/types';
import CustomerService from '../../../../../services/CustomerService';

const formControlStyles: CSSProperties = {
    minWidth: '150px',
    marginRight: 10
};

const selectorStyles = {
    container: (provided: CSSProperties) => ({
        ...provided,
        marginTop: '7px',
        width: '250px',
        display: 'inline-block'
    })
};

interface Props {
    tags?: string[];
    onChange: (selectedTags: string[]) => void;
}

async function getTagsOptions(search?: string): Promise<ReactSelectOption[]> {
    const tags = await CustomerService.getTags(search);
    return [...tags.map(tag => ({ value: tag, label: tag }))];
}

function TagSelector(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [options, setOptions] = useState<ReactSelectOption[]>([]);
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(false);

    const setSearchDebounced = useDebouncedCallback(setSearch, 500);

    const onFilterTagChange = (selectedOptions: ReactSelectOption[]) => {
        const selectedValues = selectedOptions.map(option => option.value);
        props.onChange(selectedValues);
    };

    const searchTags = (searchText: string, event: InputActionMeta) => {
        if (event.action === 'input-change') {
            setSearchDebounced(searchText);
        }

        if (event.action === 'menu-close') {
            setSearchDebounced('');
        }
    };

    const searchTagsDebounced = useDebouncedCallback(searchTags, 500);

    useEffect(() => {
        getTagsOptions(search)
            .then(setOptions)
            .catch(() => {
                const action: ToastAction = {
                    payload: {
                        variant: 'error',
                        message: 'Error on get tags'
                    },
                    type: 'ADD_TOAST'
                };
                dispatch(action);
            })
            .finally(() => setLoading(false));
    }, [dispatch, search]);

    return (
        <FormControl style={formControlStyles}>
            <ReactSelect
                styles={selectorStyles}
                placeholder={'Filter by tags'}
                onChange={onFilterTagChange}
                onInputChange={searchTagsDebounced}
                name='tags'
                isLoading={isLoading}
                options={options}
                value={
                    props.tags?.map(tag => ({ value: tag, label: tag })) || []
                }
                inputId='tag-filter'
                isMulti={true}
            />
        </FormControl>
    );
}

export default TagSelector;
