import RequestService from './RequestService';
import Customer from '../models/customer/Customer';
import UpdatePlanOptions from '../models/customer/UpdatePlanOptions';
import CustomerListFilters from '../models/list-filters/CustomerListFilters';
import { CustomerInfo } from '../models/customer/CustomerInfo';
import moment from 'moment';
import { PreassignedPlanCustomerBillingInfo } from '../models/customer/PreassignedPlanCustomerBillingInfo';
import {
    AssignedPartnerTerm,
    AssignedPartnerTermUpdate
} from '../models/customer/AssignedPartnerTerm';
import { PagedList } from '../models/PagedList';
import { CustomerAssignedToPartner } from '../models/customer/CustomerAssignedToPartner';
import GenericListFilters from '../models/GenericListFilters';

const CustomerService = {
    async getCustomerList(
        listFilters: CustomerListFilters
    ): Promise<PagedList<Customer>> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            search: listFilters.search,
            partnerId: listFilters.partnerId,
            displayDeleted: listFilters.displayDeleted
        };

        if (listFilters.plan) {
            filters.planId = listFilters.plan.id;
        }

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }
        if (listFilters.fromDate) {
            filters.fromDate = moment(listFilters.fromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.toDate) {
            filters.toDate = moment(listFilters.toDate).endOf('day').toDate();
        }
        if (listFilters.environment) {
            filters.environment = listFilters.environment;
        }
        if (listFilters.tags) {
            filters.tags = listFilters.tags;
        }
        const response = await RequestService.cnFetch(
            '/customer',
            {
                method: 'GET'
            },
            filters
        );
        return response.json();
    },

    exportCustomerList(listFilters: CustomerListFilters): Promise<Response> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            search: listFilters.search,
            partnerId: listFilters.partnerId,
            displayDeleted: listFilters.displayDeleted
        };

        if (listFilters.plan) {
            filters.planId = listFilters.plan.id;
        }

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }
        if (listFilters.fromDate) {
            filters.fromDate = moment(listFilters.fromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.toDate) {
            filters.toDate = moment(listFilters.toDate).endOf('day').toDate();
        }
        if (listFilters.environment) {
            filters.environment = listFilters.environment;
        }
        if (listFilters.tags) {
            filters.tags = listFilters.tags;
        }
        return RequestService.cnFetch('/customer/export', undefined, filters);
    },

    getCustomer(customerUUID: string): Promise<CustomerInfo> {
        return RequestService.cnFetch(`/customer/${customerUUID}`, {
            method: 'GET'
        }).then(async response => {
            const respObject: CustomerInfo = await response.json();
            return respObject;
        });
    },
    editCustomer(customer: Customer, customerUUID: string): Promise<Response> {
        const filteredCustomer = {
            billingInfo: customer.billingInfo,
            responsibleInfo: customer.responsibleInfo,
            paymentInfo: customer.paymentInfo,
            clientInfo: customer.clientInfo,
            automaticInvoiceChargeDisabled:
                customer.automaticInvoiceChargeDisabled || false
        } as Customer;
        return RequestService.cnFetch(`/customer/${customerUUID}/edit`, {
            method: 'POST',
            body: JSON.stringify(filteredCustomer)
        });
    },
    updatePlan(
        customerUUID: string,
        planId: string,
        updatePlanOptions: UpdatePlanOptions
    ): Promise<Response> {
        return RequestService.cnFetch(
            `/customer/${customerUUID}/updatePlan/${planId}`,
            {
                method: 'POST',
                body: JSON.stringify(updatePlanOptions)
            }
        );
    },
    updatePreassignedPlan(
        customerUUID: string,
        planId: string,
        customerBillingInfo: PreassignedPlanCustomerBillingInfo
    ): Promise<Response> {
        return RequestService.cnFetch(
            `/customer/${customerUUID}/updatePreassignedPlan`,
            {
                method: 'POST',
                body: JSON.stringify({
                    planId,
                    customerBillingInfo
                })
            }
        );
    },
    removePreassignedPlan(customerUUID: string): Promise<Response> {
        return RequestService.cnFetch(
            `/customer/${customerUUID}/removePreassignedPlan`,
            {
                method: 'POST',
                body: JSON.stringify({})
            }
        );
    },
    async addPartnerTerm(
        customerUUID: string,
        partnerTerm: Omit<AssignedPartnerTerm, 'assignedOn'>
    ): Promise<AssignedPartnerTerm> {
        const response = await RequestService.cnFetch(
            `/customer/${customerUUID}/addPartnerTerm`,
            {
                method: 'POST',
                body: JSON.stringify(partnerTerm)
            }
        );
        return response.json();
    },
    async editPartnerTerm(
        customerUUID: string,
        partnerTermUuid: string,
        editPartnerTerm: AssignedPartnerTermUpdate
    ): Promise<AssignedPartnerTerm> {
        const response = await RequestService.cnFetch(
            `/customer/${customerUUID}/editPartnerTerm/${partnerTermUuid}`,
            {
                method: 'PUT',
                body: JSON.stringify(editPartnerTerm)
            }
        );
        return response.json();
    },
    removePartnerTerm(customerUUID: string, termUuid: string) {
        return RequestService.cnFetch(
            `/customer/${customerUUID}/removePartnerTerm/${termUuid}`,
            {
                method: 'POST'
            }
        );
    },
    async listAssignedToPartner(
        partnerId: string,
        listFilters: GenericListFilters
    ): Promise<PagedList<CustomerAssignedToPartner>> {
        const filters = {
            fromDate: listFilters.fromDate,
            toDate: listFilters.toDate,
            search: listFilters.search,
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            sortKey: listFilters.sortBy,
            sortDirection: listFilters.direction === 'asc' ? 1 : -1
        };
        const response = await RequestService.cnFetch(
            `/customer/assignedToPartner/${partnerId}`,
            {
                method: 'GET'
            },
            filters
        );
        return response.json();
    },
    async getTags(search?: string): Promise<string[]> {
        const response = await RequestService.cnFetch(
            '/customer/tags',
            {
                method: 'GET'
            },
            {
                search
            }
        );
        return response.json();
    }
};
export default CustomerService;
