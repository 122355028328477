import React, { Fragment, ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import Tooltip from '@mui/material/Tooltip';
import InvoiceService from '../../../services/InvoiceService';
import Invoice from '../../../models/invoice/Invoice';
import { RouterMatch } from '../../../models/RouterMatch';
import { Redirect } from 'react-router';
import { ToastVariant } from '../../../reducers/Snackbar';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import InvoiceEditor from '../edit/components/InvoiceEditor';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { CSSProperties } from '@mui/styles';
import { InvoiceStatusId } from '../../../models/invoice/InvoiceStatusId';
import Paper from '@mui/material/Paper';
import CreditCardInfoComponent from '../../../components/CreditCardInfo';
import UndoIcon from '@mui/icons-material/Undo';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    invoice?: Invoice;
    existInvoice: boolean;
    isDialogEmitOpen: boolean;
    isDialogDeleteOpen: boolean;
    isDialogChargeAndEmitOpen: boolean;
    isDialogChargeReceiptOpen: boolean;
}

const chipStyles: CSSProperties = {
    height: '20px',
    marginLeft: '10px',
    fontSize: '12px'
};

const invoiceStatusStyles: CSSProperties = {
    float: 'left',
    lineHeight: '33px'
};

class InvoiceView extends Component<Props, State> {
    private invoiceId = '';

    constructor(props: Props) {
        super(props);
        if (props.match.params.id) {
            this.state = {
                isLoading: true,
                existInvoice: true,
                isDialogDeleteOpen: false,
                isDialogEmitOpen: false,
                isDialogChargeAndEmitOpen: false,
                isDialogChargeReceiptOpen: false
            };
            this.invoiceId = props.match.params.id;
            this.loadInvoice(this.invoiceId);
        } else {
            this.state = {
                isLoading: false,
                existInvoice: true,
                isDialogDeleteOpen: false,
                isDialogEmitOpen: false,
                isDialogChargeAndEmitOpen: false,
                isDialogChargeReceiptOpen: false
            };
        }
    }

    private loadInvoice = async (invoiceId: string) => {
        try {
            const invoice = await InvoiceService.getInvoice(invoiceId);
            this.setState({ ...this.state, isLoading: false, invoice });
        } catch (err) {
            this.setState(
                { ...this.state, isLoading: false, existInvoice: false },
                () => {
                    this.props.showToast('error', 'Invoice not found');
                }
            );
        }
    };

    private downloadInvoice = async () => {
        const result = await InvoiceService.downloadInvoice(this.invoiceId);
        window.open(URL.createObjectURL(await result.blob()));
    };

    private markReceiptAsPaid = async () => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.markReceiptAsPaid(this.invoiceId);
            await this.loadInvoice(this.invoiceId);
            this.props.showToast('info', 'Receipt set as paid');
        } catch (err) {
            this.props.showToast(
                'error',
                'Error trying to set receipt as paid'
            );
        }
        this.setState({ ...this.state, isLoading: false });
    };

    private hideToCustomer = async () => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.hideToCustomer(this.invoiceId);
            await this.loadInvoice(this.invoiceId);
            this.props.showToast('info', 'Invoice hidden to customer');
        } catch (err) {
            this.props.showToast('error', 'Error trying to hide the invoice');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    private showToCustomer = async () => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.showToCustomer(this.invoiceId);
            await this.loadInvoice(this.invoiceId);
            this.props.showToast('info', 'Invoice will be visible to customer');
        } catch (err) {
            this.props.showToast(
                'error',
                'Error trying to make the invoice visible to customer'
            );
        }
        this.setState({ ...this.state, isLoading: false });
    };

    private actionStyles: CSSProperties = {
        marginLeft: '8px',
        float: 'right',
        backgroundColor: 'white',
        minWidth: '48px',
        width: '48px'
    };

    private chargeInfoStyles: CSSProperties = {
        fontSize: '15px'
    };

    private disabledButtonWrap: CSSProperties = {
        float: 'right'
    };

    private isEmitting = false;
    private isChargingAndEmitting = false;
    private isChargingReceipt = false;

    private canEmit = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'draft' || invoiceStatus === 'payment-pending';

    private canCharge = (invoiceTotalAmount: number) => invoiceTotalAmount > 0;

    private canChargeReceipt = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'receipt' || invoiceStatus === 'receipt-pending';

    private canCancel = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'emitted';

    private canEdit = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'draft' || invoiceStatus === 'receipt-charged';

    private canDelete = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'draft' ||
        invoiceStatus === 'receipt-charged' ||
        invoiceStatus === 'receipt-pending' ||
        invoiceStatus === 'receipt';

    private canShowHideInvoice = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus !== 'draft';

    private emitInvoice = async () => {
        this.setState({
            ...this.state,
            isLoading: true,
            isDialogEmitOpen: false
        });
        try {
            if (this.state.invoice && this.state.invoice._id) {
                await InvoiceService.emitInvoice(this.state.invoice._id);
                this.props.showToast('success', 'Invoice emitted');
            }
        } catch (err) {
            let msg = 'Error emitting invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
        this.loadInvoice(this.invoiceId);
    };

    private chargeAndEmitInvoice = async () => {
        this.setState({
            ...this.state,
            isLoading: true,
            isDialogChargeAndEmitOpen: false
        });
        try {
            if (this.state.invoice && this.state.invoice._id) {
                await InvoiceService.chargeAndEmitInvoice(
                    this.state.invoice._id
                );
                this.props.showToast('success', 'Invoice emitted');
            }
        } catch (err) {
            let msg = 'Error emitting invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
        this.loadInvoice(this.invoiceId);
    };

    private chargeReceipt = async () => {
        this.setState({
            ...this.state,
            isLoading: true,
            isDialogChargeReceiptOpen: false
        });
        try {
            if (this.state.invoice && this.state.invoice._id) {
                await InvoiceService.chargeReceipt(this.state.invoice._id);
                this.props.showToast('success', 'Receipt charged');
            }
        } catch (err) {
            let msg = 'Error charging receipt';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
        this.loadInvoice(this.invoiceId);
    };

    private cancelInvoice = async () => {
        this.setState({ ...this.state, isLoading: true });
        try {
            if (this.state.invoice && this.state.invoice._id) {
                await InvoiceService.cancelInvoice(this.state.invoice._id);
                this.props.showToast('success', 'Invoice rectified');
            }
        } catch (err) {
            let msg = 'Error rectifying invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
        this.loadInvoice(this.invoiceId);
    };

    private deleteInvoice = async () => {
        this.setState({ ...this.state, isLoading: true });
        try {
            if (this.state.invoice && this.state.invoice._id) {
                await InvoiceService.deleteInvoice(this.state.invoice._id);
                this.props.showToast('success', 'Invoice deleted');
            }
        } catch (err) {
            let msg = 'Error deleting invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false, existInvoice: false });
    };

    private handleCloseConfirmEmitModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogEmitOpen = false;
        this.setState(stateCopy);
    };

    private handleOpenConfirmEmitModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogEmitOpen = true;
        this.setState(stateCopy);
        this.isEmitting = false;
    };

    private handleEmit = async () => {
        this.isEmitting = true;
        this.handleCloseConfirmEmitModal();
        await this.emitInvoice();
    };

    private handleChargeAndEmit = async () => {
        this.isChargingAndEmitting = true;
        this.handleCloseConfirmChargeAndEmitModal();
        await this.chargeAndEmitInvoice();
    };

    private handleChargeReceipt = async () => {
        this.isChargingReceipt = true;
        this.handleCloseConfirmChargeReceiptModal();
        await this.chargeReceipt();
    };

    private handleCloseConfirmDeleteModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogDeleteOpen = false;
        this.setState(stateCopy);
    };

    private handleOpenConfirmDeleteModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogDeleteOpen = true;
        this.setState(stateCopy);
    };

    private handleOpenConfirmChargeAndEmitModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogChargeAndEmitOpen = true;
        this.setState(stateCopy);
        this.isChargingAndEmitting = false;
    };

    private handleOpenConfirmChargeReceiptModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogChargeReceiptOpen = true;
        this.setState(stateCopy);
        this.isChargingReceipt = false;
    };

    private handleCloseConfirmChargeAndEmitModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogChargeAndEmitOpen = false;
        this.setState(stateCopy);
    };

    private handleCloseConfirmChargeReceiptModal = () => {
        const stateCopy = { ...this.state };
        stateCopy.isDialogChargeReceiptOpen = false;
        this.setState(stateCopy);
    };

    private handleDelete = () => {
        this.deleteInvoice();
        this.handleCloseConfirmDeleteModal();
    };

    private setInvoiceAsDraft = async () => {
        this.setState({ ...this.state, isLoading: true });
        try {
            if (this.state.invoice && this.state.invoice._id) {
                await InvoiceService.restoreInvoiceToDraft(
                    this.state.invoice._id
                );
                this.props.showToast('success', 'Invoice set to draft');
            }
        } catch (err) {
            let msg = 'Error on change invoice status';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.loadInvoice(this.invoiceId);
        this.setState({ ...this.state, isLoading: false });
    };

    render(): ReactElement {
        return (
            <div className='invoice-edit-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>View Invoice</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/invoice/list'>
                                Back to Invoice list
                            </BackButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        {this.state.invoice && (
                            <Grid item xs={12}>
                                <div style={invoiceStatusStyles}>
                                    <label>Invoice Status:</label>
                                    <Chip
                                        style={chipStyles}
                                        label={this.state.invoice.invoiceStatus}
                                        color='primary'
                                    />
                                    {this.state.invoice.invoiceStatus ===
                                        'payment-pending' && (
                                        <Tooltip title='Set as draft'>
                                            <Button
                                                onClick={() =>
                                                    this.setInvoiceAsDraft()
                                                }
                                                variant='contained'
                                                style={this.actionStyles}
                                            >
                                                <UndoIcon color='primary' />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </div>
                                {!this.state.invoice.invoiceStatus.includes(
                                    'receipt'
                                ) && (
                                    <Tooltip title='Rectify'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                onClick={() =>
                                                    this.cancelInvoice()
                                                }
                                                variant='contained'
                                                disabled={
                                                    !this.canCancel(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    )
                                                }
                                                style={this.actionStyles}
                                            >
                                                <CancelPresentationIcon
                                                    color={
                                                        this.canCancel(
                                                            this.state.invoice
                                                                .invoiceStatus
                                                        )
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                                <Tooltip title='Delete'>
                                    <span style={this.disabledButtonWrap}>
                                        <Button
                                            onClick={() =>
                                                this.handleOpenConfirmDeleteModal()
                                            }
                                            variant='contained'
                                            disabled={
                                                !this.canDelete(
                                                    this.state.invoice
                                                        .invoiceStatus
                                                )
                                            }
                                            style={this.actionStyles}
                                        >
                                            <DeleteIcon
                                                color={
                                                    this.canDelete(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    )
                                                        ? 'primary'
                                                        : 'disabled'
                                                }
                                            />
                                        </Button>
                                    </span>
                                </Tooltip>
                                {this.state.invoice.paymentInfo
                                    .paymentMethod === 'bank' && (
                                    <Tooltip title='Emit'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                onClick={() =>
                                                    this.handleOpenConfirmEmitModal()
                                                }
                                                variant='contained'
                                                disabled={
                                                    !this.canEmit(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    ) ||
                                                    this.state.invoice
                                                        .paymentInfo
                                                        .paymentMethod !==
                                                        'bank'
                                                }
                                                style={this.actionStyles}
                                            >
                                                <DoneOutlineIcon
                                                    color={
                                                        this.canEmit(
                                                            this.state.invoice
                                                                .invoiceStatus
                                                        )
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                                {!this.state.invoice.invoiceStatus.includes(
                                    'receipt'
                                ) && (
                                    <Tooltip title='Charge and emit'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                onClick={() =>
                                                    this.handleOpenConfirmChargeAndEmitModal()
                                                }
                                                variant='contained'
                                                disabled={
                                                    !this.canEmit(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    ) ||
                                                    this.state.invoice
                                                        .paymentInfo
                                                        .paymentMethod ===
                                                        'bank' ||
                                                    !this.canCharge(
                                                        this.state.invoice
                                                            .totalAmount
                                                    )
                                                }
                                                style={this.actionStyles}
                                            >
                                                <EuroSymbolIcon
                                                    color={
                                                        this.canEmit(
                                                            this.state.invoice
                                                                .invoiceStatus
                                                        ) &&
                                                        this.state.invoice
                                                            .paymentInfo
                                                            .paymentMethod ===
                                                            'card'
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                                {this.state.invoice.invoiceStatus.includes(
                                    'receipt'
                                ) && (
                                    <Tooltip title='Charge Receipt'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                onClick={() =>
                                                    this.handleOpenConfirmChargeReceiptModal()
                                                }
                                                variant='contained'
                                                disabled={
                                                    !this.canChargeReceipt(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    )
                                                }
                                                style={this.actionStyles}
                                            >
                                                <EuroSymbolIcon
                                                    color={
                                                        this.canChargeReceipt(
                                                            this.state.invoice
                                                                .invoiceStatus
                                                        )
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                                <Tooltip title='Edit'>
                                    <span style={this.disabledButtonWrap}>
                                        <Button
                                            href={`/invoice/${this.state.invoice._id}/edit`}
                                            variant='contained'
                                            disabled={
                                                !this.canEdit(
                                                    this.state.invoice
                                                        .invoiceStatus
                                                )
                                            }
                                            style={this.actionStyles}
                                        >
                                            <EditIcon
                                                color={
                                                    this.canEdit(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    )
                                                        ? 'primary'
                                                        : 'disabled'
                                                }
                                            />
                                        </Button>
                                    </span>
                                </Tooltip>
                                {this.state.invoice.invoiceStatus.includes(
                                    'receipt'
                                ) && (
                                    <Tooltip title='Mark as paid'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                onClick={this.markReceiptAsPaid}
                                                variant='contained'
                                                style={this.actionStyles}
                                                disabled={
                                                    !(
                                                        this.state.invoice
                                                            .invoiceStatus ===
                                                        'receipt-charged'
                                                    )
                                                }
                                            >
                                                <PlaylistAddCheck
                                                    color={
                                                        !(
                                                            this.state.invoice
                                                                .invoiceStatus ===
                                                            'receipt-charged'
                                                        )
                                                            ? 'disabled'
                                                            : 'primary'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}

                                <Tooltip title='Download pdf'>
                                    <Button
                                        onClick={this.downloadInvoice}
                                        variant='contained'
                                        style={this.actionStyles}
                                    >
                                        <PictureAsPdfIcon color='primary' />
                                    </Button>
                                </Tooltip>
                                {!this.state.invoice.isHidden && (
                                    <Tooltip title='Hide to customer'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                disabled={
                                                    !this.canShowHideInvoice(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    )
                                                }
                                                onClick={this.hideToCustomer}
                                                variant='contained'
                                                style={this.actionStyles}
                                            >
                                                <VisibilityOffIcon
                                                    color={
                                                        this.canShowHideInvoice(
                                                            this.state.invoice
                                                                .invoiceStatus
                                                        )
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                                {this.state.invoice.isHidden && (
                                    <Tooltip title='Make visible to customer'>
                                        <span style={this.disabledButtonWrap}>
                                            <Button
                                                disabled={
                                                    !this.canShowHideInvoice(
                                                        this.state.invoice
                                                            .invoiceStatus
                                                    )
                                                }
                                                onClick={this.showToCustomer}
                                                variant='contained'
                                                style={this.actionStyles}
                                            >
                                                <VisibilityIcon
                                                    color={
                                                        this.canShowHideInvoice(
                                                            this.state.invoice
                                                                .invoiceStatus
                                                        )
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )}
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            {this.state.invoice && (
                                <InvoiceEditor
                                    invoice={this.state.invoice}
                                    viewMode={true}
                                    showToast={this.props.showToast}
                                ></InvoiceEditor>
                            )}

                            {this.state.invoice &&
                                this.state.invoice.paymentData && (
                                    <Fragment>
                                        <PageHeader>
                                            Stripe payment info
                                        </PageHeader>
                                        <Paper className='cn-paper'>
                                            <Grid container spacing={1}>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    spacing={3}
                                                    style={{
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    <CreditCardInfoComponent
                                                        creditCardInfo={
                                                            this.state.invoice
                                                                .paymentData
                                                                .creditCardInfo
                                                        }
                                                    ></CreditCardInfoComponent>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    spacing={3}
                                                    style={{
                                                        marginLeft: '10px'
                                                    }}
                                                >
                                                    <Grid item xs={12}>
                                                        <h4>Charge info:</h4>
                                                        <div
                                                            className='summary-header'
                                                            style={
                                                                this
                                                                    .chargeInfoStyles
                                                            }
                                                        >
                                                            <p>Payment type</p>
                                                            <span>
                                                                {
                                                                    this.state
                                                                        .invoice
                                                                        .paymentData
                                                                        .type
                                                                }
                                                            </span>
                                                        </div>
                                                        {this.state.invoice
                                                            .paymentData
                                                            .type ===
                                                            'off-session' && (
                                                            <React.Fragment>
                                                                <div
                                                                    className='summary-header'
                                                                    style={
                                                                        this
                                                                            .chargeInfoStyles
                                                                    }
                                                                >
                                                                    <p>
                                                                        Status
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .invoice
                                                                                .paymentData
                                                                                .paymentResult
                                                                                .status
                                                                        }
                                                                    </span>
                                                                </div>
                                                                {this.state
                                                                    .invoice
                                                                    .paymentData
                                                                    .paymentResult
                                                                    .status ===
                                                                    'error' && (
                                                                    <div
                                                                        className='summary-header'
                                                                        style={
                                                                            this
                                                                                .chargeInfoStyles
                                                                        }
                                                                    >
                                                                        <p>
                                                                            Error
                                                                            message
                                                                        </p>
                                                                        <span>
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .invoice
                                                                                    .paymentData
                                                                                    .paymentResult
                                                                                    .stripePaymentIntentErrorMessage
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className='summary-header'
                                                                    style={
                                                                        this
                                                                            .chargeInfoStyles
                                                                    }
                                                                >
                                                                    <p>
                                                                        Stripe
                                                                        payment
                                                                        intent
                                                                        id
                                                                    </p>
                                                                    <span
                                                                        style={
                                                                            this
                                                                                .chargeInfoStyles
                                                                        }
                                                                    >
                                                                        <a
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            href={
                                                                                'https://dashboard.stripe.com/payments/' +
                                                                                this
                                                                                    .state
                                                                                    .invoice
                                                                                    .paymentData
                                                                                    .paymentResult
                                                                                    .stripePaymentIntentId
                                                                            }
                                                                        >
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .invoice
                                                                                    .paymentData
                                                                                    .paymentResult
                                                                                    .stripePaymentIntentId
                                                                            }
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                                {this.state
                                                                    .invoice
                                                                    .paymentData
                                                                    .paymentResult
                                                                    .status ===
                                                                    'success' && (
                                                                    <div
                                                                        className='summary-header'
                                                                        style={
                                                                            this
                                                                                .chargeInfoStyles
                                                                        }
                                                                    >
                                                                        <p>
                                                                            Charge
                                                                            id
                                                                        </p>
                                                                        <span>
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .invoice
                                                                                    .paymentData
                                                                                    .paymentResult
                                                                                    .stripeChargeId
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                        {this.state.invoice
                                                            .paymentData
                                                            .type ===
                                                            'on-session' && (
                                                            <React.Fragment>
                                                                <div
                                                                    className='summary-header'
                                                                    style={
                                                                        this
                                                                            .chargeInfoStyles
                                                                    }
                                                                >
                                                                    <p>
                                                                        Status
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .invoice
                                                                                .paymentData
                                                                                .stripePaymentIntent
                                                                                .status
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className='summary-header'
                                                                    style={
                                                                        this
                                                                            .chargeInfoStyles
                                                                    }
                                                                >
                                                                    <p>
                                                                        Stripe
                                                                        payment
                                                                        intent
                                                                        id
                                                                    </p>
                                                                    <span
                                                                        style={
                                                                            this
                                                                                .chargeInfoStyles
                                                                        }
                                                                    >
                                                                        <a
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            href={
                                                                                'https://dashboard.stripe.com/payments/' +
                                                                                this
                                                                                    .state
                                                                                    .invoice
                                                                                    .paymentData
                                                                                    .stripePaymentIntent
                                                                                    .id
                                                                            }
                                                                        >
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .invoice
                                                                                    .paymentData
                                                                                    .stripePaymentIntent
                                                                                    .id
                                                                            }
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Fragment>
                                )}
                        </Grid>
                    </Grid>
                </Container>
                {!this.state.existInvoice && (
                    <div>
                        <Redirect to='/invoice/list' />
                    </div>
                )}
                <Dialog
                    open={this.state.isDialogEmitOpen}
                    onClose={this.handleCloseConfirmEmitModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Emit Invoice
                        <br />
                    </DialogTitle>
                    <DialogContent style={{ minWidth: '450px' }}>
                        {this.state.invoice && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    Emitting invoice for the account "
                                    {
                                        (this.state.invoice as Invoice)
                                            .accountName
                                    }
                                    "
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseConfirmEmitModal}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleEmit}
                            color='primary'
                            disabled={this.isEmitting}
                        >
                            Emit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDialogDeleteOpen}
                    onClose={this.handleCloseConfirmDeleteModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Delete Invoice
                        <br />
                    </DialogTitle>
                    <DialogContent style={{ minWidth: '450px' }}>
                        <Grid container spacing={1}>
                            {this.state.invoice && (
                                <Grid item xs={12} sm={12}>
                                    Deleting invoice for the account "
                                    {
                                        (this.state.invoice as Invoice)
                                            .accountName
                                    }
                                    "
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseConfirmDeleteModal}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.handleDelete} color='primary'>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDialogChargeAndEmitOpen}
                    onClose={this.handleCloseConfirmChargeAndEmitModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Charge and Emit Invoice
                        <br />
                    </DialogTitle>
                    <DialogContent style={{ minWidth: '450px' }}>
                        {this.state.invoice && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <p>
                                        Charging and Emit invoice for the
                                        account "
                                        {this.state.invoice.accountName}"
                                    </p>
                                    <p>
                                        Amount: {this.state.invoice.totalAmount}{' '}
                                        €
                                    </p>
                                    <p>
                                        NOTE: This process will emit the invoice
                                        if the charge succeeded. In case of an
                                        error, it will send an email to the
                                        customer requiring the action to pay and
                                        it will set the invoice status as
                                        payment-pending. Invoices with this
                                        status will be visible for the customer.
                                    </p>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseConfirmChargeAndEmitModal}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleChargeAndEmit}
                            color='primary'
                            disabled={this.isChargingAndEmitting}
                        >
                            Charge and Emit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDialogChargeReceiptOpen}
                    onClose={this.handleCloseConfirmChargeReceiptModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Charge Receipt
                        <br />
                    </DialogTitle>
                    <DialogContent style={{ minWidth: '450px' }}>
                        {this.state.invoice && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <p>
                                        Sending to Shopify the info to charging
                                        the receipt for the account "
                                        {this.state.invoice.accountName}"
                                    </p>
                                    <p>
                                        Amount: {this.state.invoice.totalAmount}{' '}
                                        €
                                    </p>
                                    <p>
                                        NOTE: This process will set the invoice
                                        as receipt-charged if the charge
                                        succeeded. The receipt will be paid when
                                        the shopify period ends.
                                    </p>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseConfirmChargeReceiptModal}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleChargeReceipt}
                            color='primary'
                            disabled={this.isChargingReceipt}
                        >
                            Charge Receipt
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default InvoiceView;
