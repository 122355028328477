import React, { ReactElement } from 'react';
import Select from 'react-select';
import { ActionMeta, InputActionMeta } from 'react-select/lib/types';
import { Styles } from 'react-select/lib/styles';

export type ReactSelectOption = { value: string; label: string };

interface Props {
    placeholder?: string;
    name: string;
    isLoading?: boolean;
    onChange: (value: any, actionMeta: ActionMeta) => void;
    onInputChange: (newValue: string, actionMeta: InputActionMeta) => void;
    options?: readonly any[];
    value?: any;
    styles?: Partial<Styles>;
    inputId?: string;
    isMulti?: boolean;
}

const selectorStyles = {
    container: (provided: any) => ({
        ...provided,
        marginTop: '0px',
        width: '350px',
        display: 'inline-block'
    }),
    control: (provided: any) => {
        provided.borderRadius = 0;
        provided.borderStyle = 'none';
        provided.borderColor = 'none';
        provided.boxShadow = 'none';
        if (provided['&:hover']) {
            provided['&:hover'].borderColor = 'none';
        }
        return {
            ...provided,
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        };
    },
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none'
    }),
    option: (provided: any, state: any) => {
        provided.color = 'hsl(0,0%,20%)';
        if (state.isFocused) {
            provided.backgroundColor = 'rgba(0, 0, 0, 0.08)';
        }
        if (state.isSelected) {
            provided.backgroundColor = 'rgba(0, 0, 0, 0.14)';
        }
        if (provided[':active']) {
            provided[':active'].backgroundColor = 'rgba(0, 0, 0, 0.08)';
        }
        return {
            ...provided
        };
    }
};

export default function ReactSelect(props: Props): ReactElement {
    return (
        <Select
            placeholder={props.placeholder ? props.placeholder : 'Select....'}
            styles={{ ...selectorStyles, ...(props.styles as any) }}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            name={props.name}
            isLoading={props.isLoading}
            options={props.options}
            value={props.value}
            inputId={props.inputId}
            isMulti={props.isMulti}
        />
    );
}
