import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import ListItems from './components/list-items/ListItems';
import CustomerService from '../../../services/CustomerService';
import Customer from '../../../models/customer/Customer';
import { ToastVariant } from '../../../reducers/Snackbar';
import CustomerListFilters from '../../../models/list-filters/CustomerListFilters';
import Cookies from 'js-cookie';
import ListFilters from './components/list-items/ListFilters';
import { Button } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import { saveAs } from 'file-saver';

interface Props {
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: CustomerListFilters;
}

class CustomerList extends Component<Props, State> {
    private customers: Customer[] = [];
    private totalCustomers = 0;
    private customerFiltersCookieName = 'customerListFilters';

    constructor(props: Props) {
        super(props);
        const customerFiltersCookie = Cookies.get(
            this.customerFiltersCookieName
        );
        const filters: CustomerListFilters = customerFiltersCookie
            ? JSON.parse(customerFiltersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: '',
                  sortBy: 'createdAt',
                  direction: 'desc',
                  displayDeleted: false,
                  tags: []
              };
        this.state = {
            isLoading: true,
            filters
        };
    }

    componentDidMount() {
        this.loadCustomers(this.state.filters);
    }

    exportCustomers = async (): Promise<void> => {
        try {
            const result = await CustomerService.exportCustomerList(
                this.state.filters
            );
            const date = new Date().toISOString().split('T')[0];
            const fileName = `customers-${date}.csv`;
            saveAs(await result.blob(), fileName);
        } catch (err) {
            let msg = 'Error exporting customers';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
    };

    loadCustomers = async (filters: CustomerListFilters) => {
        let customerList: { documents: Customer[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            customerList = await CustomerService.getCustomerList(filters);
        } catch {
            this.props.showToast('error', 'Error on get customer list');
        }
        this.customers = customerList.documents;
        this.totalCustomers = customerList.totalQuantity;
        this.setState({ ...this.state, isLoading: false, filters });
    };

    onSortByChange = (fieldId: string, direction?: 'asc' | 'desc') => {
        const stateCopy = { ...this.state };
        stateCopy.isLoading = true;
        stateCopy.filters.sortBy = fieldId;
        stateCopy.filters.direction = direction;
        this.setState(stateCopy);
        this.loadCustomers(stateCopy.filters);
    };

    onFilterChange = (
        filterId: keyof CustomerListFilters,
        filterValue: any
    ) => {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    [filterId]: filterValue,
                    page: 0
                },
                isLoading: true
            },
            () => {
                this.loadCustomers(this.state.filters);
            }
        );
    };

    onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadCustomers(stateCopy.filters);
    };

    saveFilters = () => {
        Cookies.set(
            this.customerFiltersCookieName,
            JSON.stringify(this.state.filters)
        );
    };

    render(): ReactElement {
        this.saveFilters();
        return (
            <div className='invoice-list-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Customer list</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                style={{ marginRight: '15px' }}
                                onClick={this.exportCustomers}
                                disabled={this.totalCustomers === 0}
                                variant='contained'
                                color='primary'
                            >
                                Export customers list
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className='cn-paper'
                            style={{ marginBottom: '15px' }}
                        >
                            <ListFilters
                                filters={this.state.filters}
                                onFilterChange={this.onFilterChange}
                            ></ListFilters>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className='cn-paper'>
                            <ListItems
                                rowsTotal={this.totalCustomers}
                                rows={this.customers}
                                filters={this.state.filters}
                                onPaginationChange={this.onPaginationChange}
                                onSortByChange={this.onSortByChange}
                                onFilterChange={this.onFilterChange}
                            ></ListItems>
                        </Paper>
                    </Grid>
                </Container>
            </div>
        );
    }
}
export default CustomerList;
