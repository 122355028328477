import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import ListItems from './components/list-items/ListItems';
import InvoiceService from '../../../services/InvoiceService';
import Invoice from '../../../models/invoice/Invoice';
import { ToastVariant } from '../../../reducers/Snackbar';
import { RouterMatch } from '../../../models/RouterMatch';
import Cookies from 'js-cookie';
import InvoiceListFilters from '../../../models/InvoiceListFilters';
import { Button } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import ListFilters from './components/list-items/ListFilters';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: InvoiceListFilters;
}

class InvoiceList extends Component<Props, State> {
    private invoices: Invoice[] = [];
    private totalInvoices = 0;
    private invoiceFiltersCookieName = 'invoiceListFilters';

    constructor(props: Props) {
        super(props);
        const invoiceFiltersCookie = Cookies.get(this.invoiceFiltersCookieName);
        const filters: InvoiceListFilters = invoiceFiltersCookie
            ? JSON.parse(invoiceFiltersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: '',
                  sortBy: 'createdAt',
                  direction: 'desc'
              };
        this.state = {
            isLoading: true,
            filters
        };
    }

    componentDidMount() {
        this.loadInvoices(this.state.filters);
    }

    exportInvoices = async (): Promise<void> => {
        try {
            const result = await InvoiceService.exportInvoiceList(
                this.state.filters
            );
            const date = new Date().toISOString().split('T')[0];
            const fileName = `invoices-${date}.csv`;
            saveAs(await result.blob(), fileName);
        } catch (err) {
            let msg = 'Error exporting invoices';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
    };

    loadInvoices = async (filters: InvoiceListFilters) => {
        let invoiceList: { documents: Invoice[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            invoiceList = await InvoiceService.getInvoiceList(filters);
        } catch {
            this.props.showToast('error', 'Error on get invoice list');
        }
        this.invoices = invoiceList.documents;
        this.totalInvoices = invoiceList.totalQuantity;
        this.setState({ ...this.state, isLoading: false, filters });
    };

    onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadInvoices(stateCopy.filters);
    };

    onSortByChange = (fieldId: string, direction?: 'asc' | 'desc') => {
        const stateCopy = { ...this.state };
        stateCopy.isLoading = true;
        stateCopy.filters.sortBy = fieldId;
        stateCopy.filters.direction = direction;
        this.setState(stateCopy);
        this.loadInvoices(stateCopy.filters);
    };

    onFilterChange = (filterId: keyof InvoiceListFilters, filterValue: any) => {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    [filterId]: filterValue,
                    page: 0
                },
                isLoading: true
            },
            () => {
                this.loadInvoices(this.state.filters);
            }
        );
    };

    emitInvoice = async (invoiceId: string): Promise<void> => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.emitInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice emitted');
        } catch (err) {
            let msg = 'Error emitting invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }

        this.setState({ ...this.state, isLoading: false });
    };

    sendByEmail = async (invoiceIdToSend: string, emails: string[]) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.sendByEmail(invoiceIdToSend, emails);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Email sent');
        } catch (err) {
            this.props.showToast('error', 'Error on send by email');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    cancelInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.cancelInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice rectified');
        } catch (err) {
            let msg = 'Error rectifying invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
    };

    deleteInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.deleteInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice deleted');
        } catch (err) {
            let msg = 'Error deleting invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
    };

    chargeAndEmitInvoice = async (invoiceId: string): Promise<void> => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.chargeAndEmitInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice charged and emitted');
        } catch (err) {
            let msg = 'Error charging invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
    };

    hideToCustomerInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.hideToCustomer(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Invoice hidden to customer');
        } catch (err) {
            this.props.showToast('error', 'Error trying to hide the invoice');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    markReceiptAsPaid = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.markReceiptAsPaid(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Receipt mark as paid');
        } catch (err) {
            this.props.showToast(
                'error',
                'Error trying to set receipt as paid'
            );
        }
        this.setState({ ...this.state, isLoading: false });
    };

    chargeReceipt = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.chargeReceipt(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Receipt charged');
        } catch (err) {
            let msg = 'Error charging receipt';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
    };

    showToCustomerInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.showToCustomer(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Invoice will be visible to customer');
        } catch (err) {
            this.props.showToast(
                'error',
                'Error trying to make the invoice visible to customer'
            );
        }
        this.setState({ ...this.state, isLoading: false });
    };

    saveFilters = () => {
        Cookies.set(
            this.invoiceFiltersCookieName,
            JSON.stringify(this.state.filters)
        );
    };

    render(): ReactElement {
        this.saveFilters();

        return (
            <div className='invoice-list-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Invoice list</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                component={Link}
                                to='/invoice/create/invoice'
                                variant='contained'
                                color='primary'
                            >
                                Add new invoice
                            </Button>
                            <Button
                                style={{ marginRight: '15px' }}
                                component={Link}
                                to='/invoice/create/receipt'
                                variant='contained'
                                color='primary'
                                disabled={
                                    ![
                                        'receipt',
                                        'receipt-charged',
                                        'receipt-paid',
                                        'receipt-pending'
                                    ].includes(
                                        this.state.filters.invoiceStatus || ''
                                    )
                                }
                            >
                                Add new receipt charged
                            </Button>
                            <Button
                                style={{ marginRight: '15px' }}
                                onClick={this.exportInvoices}
                                disabled={this.totalInvoices === 0}
                                variant='contained'
                                color='primary'
                            >
                                Export invoices list
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className='cn-paper'
                            style={{ marginBottom: '15px' }}
                        >
                            <ListFilters
                                filters={this.state.filters}
                                onFilterChange={this.onFilterChange}
                            ></ListFilters>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className='cn-paper'>
                            <ListItems
                                onFilterChange={this.onFilterChange}
                                filters={this.state.filters}
                                rowsTotal={this.totalInvoices}
                                rows={this.invoices}
                                onPaginationChange={this.onPaginationChange}
                                emitInvoice={this.emitInvoice}
                                cancelInvoice={this.cancelInvoice}
                                deleteInvoice={this.deleteInvoice}
                                onSortByChange={this.onSortByChange}
                                sendByEmail={this.sendByEmail}
                                chargeAndEmitInvoice={this.chargeAndEmitInvoice}
                                showToCustomerInvoice={
                                    this.showToCustomerInvoice
                                }
                                hideToCustomerInvoice={
                                    this.hideToCustomerInvoice
                                }
                                markReceiptAsPaid={this.markReceiptAsPaid}
                                chargeReceipt={this.chargeReceipt}
                            ></ListItems>
                        </Paper>
                    </Grid>
                </Container>
            </div>
        );
    }
}
export default InvoiceList;
