import React, { CSSProperties, ReactElement } from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import CustomerListFilters from '../../../../../models/list-filters/CustomerListFilters';
import SearchBox from '../../../../../components/SearchBox';
import DateRangeFilters from '../../../../../components/DateRangeFilters';
import PlanSelector from './PlanSelector';
import TagSelector from './TagSelector';

const searchStyles: CSSProperties = {
    float: 'right'
};

interface Props {
    filters: CustomerListFilters;
    onFilterChange: (
        filterId: keyof CustomerListFilters,
        filterValue: any
    ) => void;
}

function ListFilters(props: Props): ReactElement {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <SearchBox
                    style={searchStyles}
                    value={props.filters.search}
                    onChange={search => props.onFilterChange('search', search)}
                />
                <PlanSelector
                    planId={props.filters.plan?.id}
                    onChange={planId =>
                        props.onFilterChange('plan', { id: planId })
                    }
                />
                <DateRangeFilters
                    filters={props.filters}
                    onFilterChange={props.onFilterChange}
                />
                <TagSelector
                    tags={props.filters.tags}
                    onChange={tags => props.onFilterChange('tags', tags)}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={props.filters.displayDeleted}
                            onChange={event => {
                                props.onFilterChange(
                                    'displayDeleted',
                                    event.target.checked
                                );
                            }}
                            value='checkedB'
                            color='primary'
                        />
                    }
                    labelPlacement='start'
                    label={
                        props.filters.displayDeleted
                            ? 'Displaying deleted'
                            : 'Hiding deleted'
                    }
                    style={{ margin: '10px 0px 0px 30px' }}
                />
            </Grid>
        </React.Fragment>
    );
}

export default ListFilters;
